<template>
    <v-col cols="6" sm="6" md="4" class="font-weight-regular text-h7 primary--text pa-0 pa-md-3">
      <v-list flat class="pa-0">
        <v-list-item class="pa-0 my-0">
          <v-list-item-icon class="mx-0 align-self-center justify-center flex-grow-0 flex-shrink-0"><v-img contain src="../assets/marker_blue.svg" max-width="18" max-height="25" /></v-list-item-icon>
          <v-list-item-title class="ml-2 ml-md-5 font-weight-regular text-subtitle-2 text-md-subtitle-1 primary--text flex-grow-1 flex-shrink-0 align-self-center" style="max-width: 100%">
            {{ text }}
          </v-list-item-title>
        </v-list-item>
      </v-list>        
    </v-col>
</template>

<script>
  export default {
    name: 'ServiceAreasTable',
    data: () => ({
        //
    }),
    props: {
        text: String,
    },        
  }
</script>