<template>
  <v-container class="ma-0 pa-0" id="whyus" fluid>
    <v-row no-gutters>
      <v-col cols=2 class="hidden-sm-and-down">
      </v-col>
      <v-col cols=12 sm=6 align-self="center" class="pb-0 pb-md-4 px-4 px-sm-6 px-lg-13">
        <v-row>
          <v-col cols=auto class="pb-0 pb-md-4">
            <p class="font-weight-medium text-h5 text-md-h4 text-lg-h3 primary--text">Why us</p>
          </v-col>
        </v-row>
        <v-row class="pt-0 pt-md-4" no-gutters>
          <v-col cols=10 md=6 class="mb-4 mb-lg-0 pr-md-1">
            <v-card elevation="0" outlined tile>
              <v-container class="ma-0 pa-0">
                <v-row no-gutters class="d-flex align-center">
                  <v-col md=12 class="flex-grow-0 flex-shrink-1">
                    <v-card-title class="font-weight-bold text-h5 text-md-h3 primary--text text-no-wrap pr-2 pr-md-4 pb-md-0">10</v-card-title>
                  </v-col>
                  <v-col md=12 class="flex-grow-1 flex-shrink-1">
                    <v-card-text class="font-weight-bold text-subtitle-2 text-md-h6 primary--text text-no-wrap pl-0 pl-md-4 pt-md-0">
                      Years of experience 
                    </v-card-text>
                  </v-col>
                </v-row>
              </v-container>
            </v-card>
          </v-col>
          <v-col cols=10 md=6 class="pl-md-1">
            <v-card elevation="0" outlined tile>
              <v-container class="ma-0 pa-0">
                <v-row no-gutters class="d-flex align-center">
                  <v-col md=12 class="flex-grow-0 flex-shrink-1">
                    <v-card-title class="font-weight-bold text-h5 text-md-h3 primary--text text-no-wrap pr-2 pr-md-4 pb-md-0">100%</v-card-title>
                  </v-col>
                  <v-col md=12 class="flex-grow-1 flex-shrink-1">
                    <v-card-text class="font-weight-bold text-subtitle-2 text-md-h6 primary--text pl-0 pl-md-4 pt-md-0">
                      Recommended 
                    </v-card-text>
                  </v-col>
                </v-row>
              </v-container>
            </v-card>
          </v-col>
        </v-row>
        <p class="font-weight-regular text-subtitle-2 text-md-subtitle-1 mt-5 mt-md-10"><strong>Mold Experts of Atlanta</strong> is a locally run and operated company. We specialize in all facets of mold removal.</p>
        <p class="font-weight-regular text-subtitle-2 text-md-subtitle-1 mt-3 mt-md-7">We are extremely competitive and understand the value of building lifelong relationships with our customers. Once you use us, you won’t have to find another mold removal company again.</p>
      </v-col>

      <v-col cols=12 sm=6 md=4 class="d-none d-sm-block pa-0">
        <v-row no-gutters>
          <v-col class="white"><p class="mt-5 mt-sm-11"></p></v-col>
        </v-row>
        <v-row no-gutters>
          <v-container class="ma-0 pa-0 pr-4 pr-sm-6 pr-lg-13">
            <v-row no-gutters>
              <v-col class="secondary">
                <v-img class="mt-n11" position="right" src="../assets/images/whyus_3x_md.png" eager
                :srcset="require('../assets/images/whyus_3x_lg.png') + ' 1689w,' +
                        require('../assets/images/whyus_2x_lg.png') + ' 1126w,' +
                        require('../assets/images/whyus_3x_md.png') + ' 840w,' +
                        require('../assets/images/whyus_3x_sm.png') + ' 711w,' +
                        require('../assets/images/whyus_2x_md.png') + ' 569w,' +
                        require('../assets/images/whyus_1x_lg.png') + ' 563w,' +
                        require('../assets/images/whyus_2x_sm.png') + ' 474w,' +
                        require('../assets/images/whyus_1x_md.png') + ' 280w,' +
                        require('../assets/images/whyus_1x_sm.png') + ' 237w,'" />
              </v-col>
            </v-row>
          </v-container>
        </v-row>
      </v-col>
    </v-row>
    <v-row no-gutters>
      <v-col cols=2 class="hidden-sm-and-down">
      </v-col>
      <v-col cols=12 md=10 class="px-sm-6 px-lg-13 px-4">
        <v-container class="ma-0 pa-0 pl-0 d-sm-none mb-4">
          <v-row no-gutters>
            <v-col class="white"><p class="mt-5 mt-md-11"></p></v-col>
          </v-row>
          <v-row no-gutters>
            <v-col class="secondary">
              <v-img class="mt-n11" src="../assets/images/whyus_3x_md.png" eager
              :srcset="require('../assets/images/whyus_3x_lg.png') + ' 1689w,' +
                      require('../assets/images/whyus_2x_lg.png') + ' 1126w,' +
                      require('../assets/images/whyus_3x_md.png') + ' 840w,' +
                      require('../assets/images/whyus_3x_sm.png') + ' 711w,' +
                      require('../assets/images/whyus_2x_md.png') + ' 569w,' +
                      require('../assets/images/whyus_1x_lg.png') + ' 563w,' +
                      require('../assets/images/whyus_2x_sm.png') + ' 474w,' +
                      require('../assets/images/whyus_1x_md.png') + ' 280w,' +
                      require('../assets/images/whyus_1x_sm.png') + ' 237w,'" />
            </v-col>
          </v-row>
        </v-container>
        <v-container class="ma-0 pl-0">
          <v-row> 
            <WhyUsReasons v-for="item in reasons" :key="item.title" :text="item.title"/>
          </v-row>
        </v-container>

        <p class="primary--text text-subtitle-2 text-md-subtitle-1 font-weight-regular mb-md-8">Call us today for a free estimate on any of your mold remediation needs!</p>
        <v-btn x-large depressed tile class="px-8 py-5 px-md-12 py-md-8 secondary white--text font-weight-medium text-none" @click="$vuetify.goTo('#contactus')">Contact us</v-btn>
      </v-col>
    </v-row> 
  </v-container>  
</template>

<script>
  import WhyUsReasons from './WhyUsReasons'
  export default {
    name: 'WhyUs',
    components: {
      WhyUsReasons,
    },    
    data: () => ({
      reasons: [
        { title: "Quick response time" },
        { title: "Incredible customer service" },
        { title: "High level of professionalism" },
        { title: "Work completed on time" },
        { title: "Competitive prices" },
        { title: "We understand our customers’ needs" },
        { title: "Latest technology and equipment" },
        { title: "Your property will be treated with care" },
      ]
    }),
  }
</script>

<style scoped>
.theme--light.v-sheet--outlined {
    border-top-color: #004dcb;
    border-bottom-color: #004dcb;
    border-left-color: #004dcb;
    border-right-color: #004dcb;
  }
</style>