<template>
  <v-container id="equipment" class="ma-0 pa-0" fluid>
    <v-row no-gutters>
      <v-col cols=2 class="hidden-sm-and-down">
      </v-col>
      <v-col cols=12 md=5 class="px-4 px-sm-6 px-lg-13">      
        <p class="font-weight-medium text-h5 text-md-h4 text-lg-h3 primary--text">Equipment</p>
      </v-col>
      <v-spacer />
    </v-row>
    <v-row no-gutters class="mt-md-8">
      <v-col cols=2 align="right" class="hidden-sm-and-down order-md-0"></v-col>
      <v-col cols=12 sm=7 align="left" class="px-4 px-sm-6 px-lg-13 order-last order-sm-0 order-md-1">
        <v-container>
          <v-row>
            <v-col cols=1 class="px-0">              
              <v-divider vertical role="presentation" class="secondary" />
            </v-col>
            <v-col cols=11 class="pl-0 text-subtitle-1 font-weight-bold">
              Air Scrubber:
            </v-col>
          </v-row>
          <v-row>
            <v-col cols=1></v-col>
            <v-col cols=11 class="pt-0 pl-0 text-subtitle-1 font-weight-regular">
              Portable filtration system that efficiently removes particles from the air to help improve air quality.
            </v-col>
          </v-row>
        </v-container>
      </v-col>
      <v-col class="hidden-sm-and-down order-md-2 order-sm-1" cols=1></v-col>
      <v-col cols=12 sm=2 class="px-4 px-sm-0 order-md-3 order-sm-2 order-first">
        <v-img contain eager class="mr-md-13" :position="mobileEquipmentImagesPosition" :max-height="scrubberHeight" src="../assets/images/scrubber_3x_md.jpg"
        :srcset="require('../assets/images/scrubber_3x_lg.jpg') + ' 342w,' +
                require('../assets/images/scrubber_3x_md.jpg') + ' 285w,' +
                require('../assets/images/scrubber_2x_lg.jpg') + ' 228w,' +
                require('../assets/images/scrubber_2x_md.jpg') + ' 190w,' +
                require('../assets/images/scrubber_1x_lg.jpg') + ' 114w,' +
                require('../assets/images/scrubber_1x_md.jpg') + ' 95w,'" />
      </v-col>
    </v-row>
    <v-row no-gutters class="mt-md-8">
      <v-col cols=2 align="right" class="hidden-sm-and-down order-md-0"></v-col>
      <v-col cols=12 sm=7 align="left" class="px-4 px-sm-6 px-lg-13 order-last order-sm-0 order-md-1">
        <v-container>
          <v-row>
            <v-col cols=1 class="px-0">              
              <v-divider vertical role="presentation" class="secondary" />
            </v-col>
            <v-col cols=11 class="pl-0 text-subtitle-1 font-weight-bold">
              Air Mover:
            </v-col>
          </v-row>
          <v-row>
            <v-col cols=1></v-col>
            <v-col cols=11 class="pt-0 pl-0 text-subtitle-1 font-weight-regular">
              Produces high volumes of air movement to allow for proper drying to occur.
            </v-col>
          </v-row>
        </v-container>
      </v-col>
      <v-col class="hidden-sm-and-down order-md-2 order-sm-1" cols=1></v-col>
      <v-col cols=12 sm=2 align="right" class="px-4 px-sm-0 order-md-3 order-sm-2 order-first">
        <v-img contain eager class="mr-13" :position="mobileEquipmentImagesPosition" :max-height="airMoverHeight" src="../assets/images/mover_3x_md.jpg"
        :srcset="require('../assets/images/mover_3x_lg.jpg') + ' 342w,' +
                require('../assets/images/mover_3x_md.jpg') + ' 285w,' +
                require('../assets/images/mover_2x_lg.jpg') + ' 228w,' +
                require('../assets/images/mover_2x_md.jpg') + ' 190w,' +
                require('../assets/images/mover_1x_lg.jpg') + ' 114w,' +
                require('../assets/images/mover_1x_md.jpg') + ' 95w,'" />
      </v-col>
    </v-row>
    <v-row no-gutters class="mt-md-8">
      <v-col cols=2 align="right" class="hidden-sm-and-down order-md-0"></v-col>
      <v-col cols=12 sm=7 align="left" class="px-4 px-sm-6 px-lg-13 order-last order-sm-0 order-md-1">
        <v-container>
          <v-row>
            <v-col cols=1 class="px-0">              
              <v-divider vertical role="presentation" class="secondary" />
            </v-col>
            <v-col cols=11 class="pl-0 text-subtitle-1 font-weight-bold">
              Dehumidifier:
            </v-col>
          </v-row>
          <v-row>
            <v-col cols=1></v-col>
            <v-col cols=11 class="pt-0 pl-0 text-subtitle-1 font-weight-regular">
              Much like air conditioners; warmer, moist air enters the dehumidifier and then crosses refrigerant cooled coils, causing the moisture in the air to condense on coils within the machine.
            </v-col>
          </v-row>
        </v-container>
      </v-col>
      <v-col class="hidden-sm-and-down order-md-2 order-sm-1" cols=1></v-col>
      <v-col cols=12 sm=2 align="right" class="px-4 px-sm-0 order-md-3 order-sm-2 order-first">
        <v-img contain eager class="mr-13" :position="mobileEquipmentImagesPosition" :max-height="dehumidifierHeight" src="../assets/images/dehumidifier_3x_md.jpg"
        :srcset="require('../assets/images/dehumidifier_3x_lg.jpg') + ' 225w,' +
                require('../assets/images/dehumidifier_3x_md.jpg') + ' 183w,' +
                require('../assets/images/dehumidifier_2x_lg.jpg') + ' 150w,' +
                require('../assets/images/dehumidifier_2x_md.jpg') + ' 122w,' +
                require('../assets/images/dehumidifier_1x_lg.jpg') + ' 75w,' +
                require('../assets/images/dehumidifier_1x_md.jpg') + ' 61w,'" />
      </v-col>
    </v-row>        
  </v-container>
</template>

<script>
  export default {
    name: 'Equipment',
    data: () => ({
      //
    }),
    computed: {
      scrubberHeight () {
        if (this.$vuetify.breakpoint.mdAndUp) {
          return 120
        }
        else {
          return 91
        }
      },
      airMoverHeight () {
        if (this.$vuetify.breakpoint.mdAndUp) {
          return 120
        }
        else {
          return 89
        }
      },
      dehumidifierHeight () {
        if (this.$vuetify.breakpoint.mdAndUp) {
          return 150
        }
        else {
          return 117
        }
      },
      mobileEquipmentImagesPosition () {
        if (this.$vuetify.breakpoint.mdAndUp) {
          return "center"
        }
        else {
          return "left"
        }
      },
    }
  }
</script>