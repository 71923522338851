<template>
  <v-container id="orderprocess" class="ma-0 pa-0" fluid>
    <v-row no-gutters>
      <v-col cols=2 class="hidden-sm-and-down">
      </v-col>
      <v-col cols=12 md=8 class="px-4 px-sm-6 px-lg-13"> 
        <p class="font-weight-medium text-h5 px-0 text-md-h4 text-lg-h3 primary--text">Order Process</p>
          <v-container class="ma-0 pa-0">
            <v-row class="pl-4">
              <v-col class="pl-0" cols="12" sm="6" v-for="(item, i) in process" :key=i :class="i === 4 && 'd-none d-xl-flex d-lg-flex d-md-flex d-sm-flex d-xl-inline-flex d-lg-inline-flex'">
                <v-card elevation="0" tile :class="i === 4 ? 'white' : 'secondary'" height="100%" class="mr-lg-4 mb-lg-2 justify-center flex-grow-1 align-self-stretch pa-4 white--text">
                  <v-card-title class="font-weight-bold text-subtitle-1 text-md-h6">
                    {{ item.step }}
                  </v-card-title>
                  <v-card-subtitle class="pt-4 pb-4">
                    <v-divider class="white" />
                  </v-card-subtitle>
                  <v-card-text class="white--text font-weight-bold text-subtitle-2 text-md-subtitle-1">
                    {{ item.text }}
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>
          </v-container>
      </v-col>
      <v-col cols=2 class="hidden-sm-and-down">
      </v-col>
    </v-row>   
  </v-container>
</template>

<script>
  export default {
    name: 'OrderProcess',
    data: () => ({
      process: [
        { 
          step: "01", 
          text: "Call 470-601-1911 for immediate help. Receive site inspection for your emergency mold problem in 45 minutes or less!",
        },
        { 
          step: "02", 
          text: "Our technicians will identify the scope of work and you will receive an estimate.",
        },
        { 
          step: "03", 
          text: "Upon your approval, we will take care of the job from A-Z and provide a scope of work.",
        },
        { 
          step: "04", 
          text: "We will work with you, your insurance carrier, and the adjuster to provide the best level of communication between all parties and to expedite the process.",
        },
        {
          step: "",
          text: "",
        },
        { 
          step: "05", 
          text: "Our technicians will make your living area or workspace better than it was before.",
        },                                     
      ],
    }),
  }
</script>