<template>
  <v-container id="pricing" class="ma-0 pr-0" fluid>
    <v-row class="mr-0 mr-md-13">
      <v-col cols=2 class="hidden-sm-and-down">
      </v-col>
      <v-col cols=12 md=10 class="px-4 px-sm-6 px-lg-13">      
      <p class="pl-0 font-weight-medium text-h5 px-4 px-md-0 text-md-h4 text-lg-h3 primary--text">Pricing</p>
        <v-card class="ma-0" flat tile>
          <v-row no-gutters>
            <v-col cols=12 sm=6 align="left" class="d-flex flex-grow-1 mt-5 mt-md-12">
              <v-card class="d-flex flex-column justify-space-around" flat tile>
                <v-row v-for="(item,i) in prices" :key="i" class="mb-4 mb-md-12">
                  <v-col class="flex-grow-0 flex-shrink-0 align-self-start py-0 py-md-3">
                    <v-card height="24" width="1" flat tile class="pa-0 ma-0">             
                      <v-divider vertical role="presentation" class="secondary" />
                    </v-card>
                  </v-col>
                  <v-col class="py-0 px-0 px-md-3 py-md-3 ml-0 ml-md-2 text-subtitle-1 text-md-h6 font-weight-bold flex-grow-1 flex-shrink-0 align-self-start" style="max-width: 100%">
                    {{ item.text }}<span class="font-weight-regular"><br/>{{ item.notes }}</span>
                  </v-col>
                  <v-col class="py-0 text-h6 text-md-h4 font-weight-bold flex-grow-0 flex-shrink-0 pt-0 pt-md-3 align-self-start">
                    ${{ item.price }}
                  </v-col>
                </v-row>
              </v-card>
            </v-col>
            <v-col cols=12 sm=6 align="center" class="pt-4 pt-md-3">
              <v-card class="py-5 pl-7" align="left" flat outlined tile width="360">
                <v-container>
                <v-row no-gutters class="d-flex justify-start">
                  <v-col class="text-h5 text-md-h4 font-weight-bold flex-grow-0 align-self-center text-no-wrap">
                    Free +
                  </v-col>
                  <v-col class="pl-4 flex-grow-0 align-self-center">
                     <v-img src="../assets/giftbox.svg" height="30" width="30" />
                  </v-col>
                </v-row>
                <v-row no-gutters class="mt-3 mb-5">
                  <v-col>
                  <v-divider class="secondary" width="250" />
                  </v-col>
                </v-row>
                <v-row no-gutters v-for="(item, i) in free_items" :key="i">
                  <v-col>
                    <v-list flat class="pa-0">
                      <v-list-item class="pa-0 my-0">
                        <v-list-item-icon class="mx-0 align-self-center"><v-img src="../assets/sq_bullet.svg" max-width="7" /></v-list-item-icon>
                        <v-list-item-title class="text-body-2 text-md-body-1 font-weight-medium align-self-center">{{ item.text }}</v-list-item-title>
                      </v-list-item>
                    </v-list>
                  </v-col>
                </v-row>
                <v-row no-gutters>
                  <v-col>
                    <v-list flat class="pa-0">
                      <v-list-item class="pa-0 my-0">
                        <v-list-item-icon class="mx-0 align-self-center"><v-img src="../assets/sq_bullet.svg" max-width="7" /></v-list-item-icon>
                        <v-list-item-title class="text-body-2 text-md-body-1 primary--text font-weight-black align-self-center">Get a $100 bonus for<br/>a new client referral</v-list-item-title>
                      </v-list-item>
                    </v-list>
                  </v-col>
                </v-row>
                </v-container>
              </v-card>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
    </v-row>        
  </v-container>
</template>

<script>
  export default {
    name: 'Pricing',
    data: () => ({
      prices: [
        { text: "Residential Mold Inspection", notes: "(up to 2000 sq. ft.)", price: "300"},
        { text: "Commercial Mold Inspection", notes: "(up to 3000 sq. ft.)", price: "300"},
        { text: "Apartment Mold Inspection", price: "150"},
        { text: "Air Sampling", price: "300"},
      ],
      free_items: [
        { text: "Mold Remediation" },
        { text: "Mold Encapsulation" },
        { text: "Mold Fogging" },
        { text: "Drywall Restoration" },
        { text: "Painting" },
        { text: "Complete Rebuild/ Remodel" },
        { text: "Vapor Barrier installation in Crawl Space" },
        { text: "Crawl Space encapsulation" },
        { text: "Water damage restoration" },
        { text: "Dehumidifier, Air Cleaner" }, 
        { text: "Sanitizing" }, 
        { text: "Antimicrobial Wash" },
        { text: "Antimicrobial Fogging" },
      ],
    }),
    
  }
</script>

<style scoped>
  div >>> .theme--light.v-sheet--outlined {
    border-top-color: #004dcb;
    border-bottom-color: #004dcb;
    border-left-color: #004dcb;
    border-right-color: #004dcb;
    border-width: 3px;
  }
  /* This is for the "Free +" text */
  div >>> .text-h4.text-no-wrap {
    line-height: 58.6px
  }
  div >>> .v-list-item__title {
    line-height: 30px;
    white-space: normal;
  }
  div >>> .v-list-item__icon {
    margin: 0px;
  }
  div >>> .v-list-item {
    min-height: 30px;
  }
</style>