<template>
  <v-container id="home" class="ma-0 pa-0" fluid>
    <v-row class="d-flex justify-space-between ma-0 px-0 px-sm-6 px-lg-13">
        <v-col cols=1 class="hidden-sm-and-down">
        </v-col>
        <v-col cols=11 sm=5 class="ma-0 px-4 px-sm-0 pr-lg-12"> 
          <v-card flat tile>
            <v-card-text class="pl-0 font-weight-medium text-subtitle-1 text-md-h6 primary--text">
                Mold Experts of Atlanta LLC offers:
                Residential & Commercial
            </v-card-text>
          </v-card>
          <v-divider role="presentation" class="pl-0 secondary" width="62" />
          <v-card flat title>
            <v-card-title class="pl-0 font-weight-medium text-h6 text-md-h5 text-lg-h4 primary--text text-no-wrap">
            Mold Remediation &<br/>Water Damage Restoration Services
            </v-card-title>
            <v-card-text class="pl-0 font-weight-regular text-subtitle-1 text-md-h6 primary--text">
                Concerned about mold in your home or business? Certified Mold Professionals are here to help you.  
            </v-card-text>
            <v-card-text class="pl-0 d-flex justify-space-between justify-sm-start">
                <v-btn x-large depressed tile class="px-8 py-5 px-md-12 py-md-8 secondary white--text font-weight-medium text-none" @click="$vuetify.goTo('#howitworks')">Learn more</v-btn>
                <v-btn x-large depressed elevation=0 class="px-8 py-5 px-md-12 py-md-8 font-weight-medium text-none white secondary--text offset-underline" @click="$vuetify.goTo('#servicearea')">Service area</v-btn>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col cols=12 sm=5 class="ma-0 px-4 px-sm-0 pl-lg-12">
          <v-img src="../assets/images/family_3x_md.jpg" 
          :srcset="require('../assets/images/family_3x_lg.jpg')+' 2490w, ' +
          require('../assets/images/family_2x_lg.jpg') + ' 1660w, ' +
          require('../assets/images/family_3x_md.jpg') + ' 1032w, ' +
          require('../assets/images/family_3x_sm.jpg') + ' 870w, ' +
          require('../assets/images/family_1x_lg.jpg') + ' 830w, ' +
          require('../assets/images/family_2x_md.jpg') + ' 688w, ' +
          require('../assets/images/family_2x_sm.jpg') + ' 580w, ' +
          require('../assets/images/family_1x_md.jpg') + ' 344w, ' + 
          require('../assets/images/family_1x_sm.jpg') + ' 290w'" />
        </v-col>
    </v-row>
  </v-container>
</template>

<script>
  export default {
    name: 'Home',
    data: () => ({
      //
    }),
  }
</script>