<template>
    <v-col v-if="entry > mobileItemsCutoffCount && useShowMore ? showMoreState : 'true'" cols="12" sm="6" md="4" class="font-weight-regular text-subtitle-2 text-md-subtitle-1 primary--text py-0 py-md-3">
        <v-container class="ma-0 px-4 px-md-0 pb-3 py-md-3">
        <v-row class="d-flex align-center">
        <v-col class="flex-grow-0 flex-shrink-1">
          <!--<v-icon class="secondary--text mr-4">mdi-check-bold</v-icon>-->
          <v-img src="../assets/check_blue.svg" contain max-height=10 />
        </v-col>
        <v-col class="ml-1">{{ text }}</v-col>
        </v-row>
        </v-container>
    </v-col>
</template>

<script>
  export default {
    name: 'ServicesTable',
    props: {
        text: String,
        entry: Number,
        showMoreState: Boolean,
    },
    data: () => ({
      mobileItemsCutoffCount: 6,
    }),
    computed: {
      useShowMore () {
        if (this.$vuetify.breakpoint.width >=768) {
          return false
        }
        else {
          return true
        }
      },
    },
  }
</script>