<template>
  <v-container id="services" class="ma-0 pa-0" fluid>
    <v-row no-gutters>
      <v-col cols=2 class="hidden-sm-and-down">
      </v-col>
      <v-col cols=12 sm=6 class="py-0 pb-md-3">      
      <p class="font-weight-medium px-4 px-sm-6 px-lg-13 text-h5 text-md-h4 text-lg-h3 primary--text">Services</p>
      <p class="font-weight-regular text-subtitle-2 text-md-subtitle-1 px-4 px-sm-6 px-lg-13">We are certified mold remediation professionals. Please call us today with any questions or concerns.</p>
      <v-btn depressed elevation=0 class="pa-0 ma-0 px-4 px-sm-6 px-lg-13 font-weight-medium text-none white secondary--text offset-underline" @click="$vuetify.goTo('#contactus')">Contact us</v-btn>
      </v-col>
      <v-spacer />
    </v-row>
    <v-row no-gutters>
      <v-col cols=2 class="hidden-sm-and-down">
      </v-col>
      <v-col cols=10>
      <v-container class="ma-0 pl-4 pl-sm-6 pl-lg-13">
        <v-row>        
          <ServicesTable v-for="(item, i) in services" :key="item.title" :entry="i" :text="item.title" :showMoreState="showMoreState"/>
        </v-row>
        <v-row v-if="useShowMore" class="ma-0 mt-5 pl-4">
          <v-col class="ma-0 pa-0">
          <v-btn x-large depressed tile class="px-8 py-5 secondary white--text font-weight-medium text-none" @click="showMoreState = !showMoreState; hideShowMoreButton = true">Show more</v-btn>
          </v-col>
        </v-row>
      </v-container>
      </v-col>
    </v-row>      
  </v-container>
</template>

<script>
  import ServicesTable from './ServicesTable'; 
  export default {
    components: {
      ServicesTable,
    },  
    name: 'Services',
    data: () => ({
      services: [
        { title: "Mold Inspection" },
        { title: "Mold Testing " },
        { title: "Mold Remediation" },
        { title: "Mold Encapsulation" },
        { title: "Mold Fogging " },
        { title: "Drywall Restoration" },
        { title: "Painting" },
        { title: "Complete Rebuild/ Remodel" },
        { title: "Vapor Barrier installation in Crawl Space" },
        { title: "Crawl Space encapsulation" },
        { title: "Water damage restoration" },
        { title: "Dehumidifier, Air Cleaner" },
        { title: "Sanitizing" },
        { title: "Antimicrobial Wash" },
        { title: "Antimicrobial Fogging" },
      ],
      showMoreState: false,
      hideShowMoreButton: false
    }),

    computed: {
      numItems () {
        return length(this.services)
      },

      cols () {
        const { lg, sm } = this.$vuetify.breakpoint
        return lg ? [3, 9] : sm ? [9, 3] : [6, 6]
      },

      useShowMore () {
        if (this.$vuetify.breakpoint.width >= 768) {
          return false
        }
        else {
          return true && !this.hideShowMoreButton
        }
      },
    },
    
  }
</script>