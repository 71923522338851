<template>
    <v-tab class="font-weight-medium text-subtitle-1 primary--text text-none">
        <v-container>
            <v-row @click="$vuetify.goTo(target)">
                <v-col cols="auto" class="ma-0 pa-0 text-subtitle-1 font-weight-medium">
                        {{ title }}
                <!-- This page https://stackoverflow.com/questions/56806020/vue-cannot-find-the-dynamic-source-of-the-vuetify-v-img-element
                    helped me get the image working. 
                    This page explains the webpack dependency graph, which I think is really the issue:
                    https://cli.vuejs.org/guide/html-and-static-assets.html#static-assets-handling
                -->
                </v-col>
                <v-col v-if="imgname" class="ma-0 pa-0" align-self="center">
                    <v-img v-if="imgname" :class="classval" :src="require('../assets/' + imgname)" :height="imgheight" :width="imgwidth"> </v-img>
                </v-col>
            </v-row>
        </v-container>
    </v-tab>
</template>

<script>
  export default {
    name: 'NavBarItem',

    data: () => ({
        //
    }),

    computed: {
      target () {
          return this.link
      }
    },

    props: {
        title: String,
        imgname: String,
        imgwidth: Number,
        imgheight: Number,
        classval: String,
        link: String
    },
  }
</script>