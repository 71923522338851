<template>
  <v-footer padless color="secondary" class="pt-0 pt-md-16">
    <v-container class="pb-0 pt-0 pt-md-3">
      <v-row no-gutters class="d-none d-md-block">
        <v-col>
          <v-list flat class="pa-0">
            <v-list-item class="pa-0 my-0 secondary">
              <v-list-item-title class="white--text secondary text-h6 font-weight-medium align-self-center">Mold Experts of Atlanta</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-col>
      </v-row>      
      <v-row class="d-none d-md-block">
        <v-col class="align-self-center">
          <v-row no-gutters class="mt-8">
            <v-col>
              <v-list flat class="pa-0">
                <v-list-item class="pa-0 my-0 secondary">
                  <v-list-item-icon class="mx-0 secondary align-self-center justify-center flex-grow-0 flex-shrink-0 white--text"><icon-phone :width="phoneIcon.width" :height="phoneIcon.height"/></v-list-item-icon>
                  <v-list-item-title class="ml-5 white--text secondary text-h6 font-weight-medium align-self-center">
                    <a href="tel:470-601-1911" class="text-decoration-none white--text">470-601-1911</a>
                  </v-list-item-title>
                </v-list-item>
              </v-list>
            </v-col>
          </v-row>
          <v-row no-gutters class="mt-8">
            <v-col>
              <v-list flat class="pa-0">
                <v-list-item class="pa-0 my-0 secondary">
                  <v-list-item-icon class="mx-0 secondary align-self-center justify-center flex-grow-0 flex-shrink-0 white--text"><icon-mail :width="mailIcon.width" :height="mailIcon.height"/></v-list-item-icon>
                  <v-list-item-title class="ml-5 white--text secondary text-h6 font-weight-medium align-self-center">
                    <a href="mailto:contact@moldexpertsofatlanta.com" traget="_blank" class="text-decoration-none white--text">contact@moldexpertsofatlanta.com</a>
                  </v-list-item-title>
                </v-list-item>
              </v-list>
            </v-col>
          </v-row>
          <v-row no-gutters class="mt-8">
            <v-col>
              <v-list flat class="pa-0">
                <v-list-item class="pa-0 my-0 secondary">
                  <v-list-item-icon class="mx-0 secondary align-self-center justify-center flex-grow-0 flex-shrink-0 white--text"><icon-marker :width="markerIcon.width" :height="markerIcon.height"/></v-list-item-icon>
                  <v-list-item-title class="ml-5 white--text secondary text-h6 font-weight-medium align-self-center">
                    160 Owens Rd, Dallas, GA 30157
                  </v-list-item-title>
                </v-list-item>
              </v-list>
            </v-col>
          </v-row>
          <v-row no-gutters class="mt-8">
            <v-col>
              <v-list flat class="pa-0">
                <v-list-item class="pa-0 my-0 secondary">
                  <v-list-item-icon class="mx-0 secondary align-self-center justify-center flex-grow-0 flex-shrink-0 white--text">
                    <icon-clock :width="clockIcon.width" :height="clockIcon.height"
                      :backgroundColor="this.$vuetify.theme.themes.light.secondary"/>
                  </v-list-item-icon>
                  <v-list-item-title class="ml-5 white--text secondary text-h6 font-weight-medium flex-grow-1 flex-shrink-0 align-self-center">
                    Monday - Friday:<br/>8:00 am - 5:00 pm
                  </v-list-item-title>
                </v-list-item>
              </v-list>
            </v-col>
          </v-row>
        </v-col>
        <v-col class="align-self-center">
          <v-container>
            <v-row>
              <v-col cols="12" sm="6" md="4" class="font-weight-medium text-body-1 white--text"
                v-for="(item, i) in footer_items" :key="i"
              >
                  <a @click="$vuetify.goTo(item.link)" class="text-decoration-none white--text">{{ item.title }}</a>
              </v-col>              
            </v-row>
          </v-container>
        </v-col>
      </v-row>
      <v-row no-gutters class="mt-0 mt-md-8 pt-4 pt-md-12 mb-1 pb-4">
        <v-col cols=12 align="center" class="align-self-center">
          <p class="mb-0 white--text secondary text-body-2 text-md-body-1 font-weight-normal align-self-center">© {{ new Date().getFullYear() }} - All rights reserved</p>
        </v-col>
      </v-row>  
    </v-container>
  </v-footer>
</template>

<script>
  import IconMail from './icons/IconMail.vue'
  import IconClock from './icons/IconClock.vue'
  import IconMarker from './icons/IconMarker.vue'
  import IconPhone from './icons/IconPhone.vue'
  export default {
    name: 'Footer',
    components: {
      IconMail,
      IconClock,
      IconMarker,
      IconPhone
    },
    data: () => ({
      footer_items: [
        { title: "Home", link: "#home" },
        { title: "About Us", link: "#about" },
        { title: "Services", link: "#services" },
        { title: "Mold Types", link: "#moldtypes" },
        { title: "How it Works", link: "#howitworks" },
        { title: "Equipment", link: "#equipment" },
        { title: "Service Area", link: "#servicearea" },
        { title: "Why Us", link: "#whyus" },
        { title: "Gallery", link: "#gallery" },
        { title: "Reviews", link: "#reviews" },
        { title: "Order Process", link: "#orderprocess" },
        { title: "Pricing", link: "#pricing" },
        { title: "Contact Us", link: "#contactus" }
      ],
    }),
    computed: {
      clockIcon () {
        var regular = {
          width: 25,
          height: 25
        }
        var small = {
          width: 18,
          height: 18
        }
        if (this.$vuetify.breakpoint.mdAndUp) { 
          return regular
        }
        else {
          return small
        }
      },
      mailIcon () {
        var regular = {
          width: 25,
          height: 17
        }
        var small = {
          width: 18,
          height: 12
        }
        if (this.$vuetify.breakpoint.mdAndUp) { 
          return regular
        }
        else {
          return small
        }
      },
      phoneIcon () {
        var regular = {
          width: 25,
          height: 25
        }
        var small = {
          width: 13,
          height: 13
        }
        if (this.$vuetify.breakpoint.mdAndUp) { 
          return regular
        }
        else {
          return small
        }
      },
      markerIcon () {
        var regular = {
          width: 18,
          height: 25
        }
        var small = {
          width: 13,
          height: 18
        }
        if (this.$vuetify.breakpoint.mdAndUp) { 
          return regular
        }
        else {
          return small
        }
      },  
    }
  }
</script>
