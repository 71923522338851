<template>
  <v-container class="ma-0" id="gallery" fluid>
    <v-row>
      <v-col cols=2 class="hidden-sm-and-down">
      </v-col>
      <v-col cols=12 md=8>
        <v-row>
          <v-col align="center" justify="center">
            <p class="font-weight-medium text-h5 px-4 px-md-0 text-md-h4 text-lg-h3 primary--text">Gallery</p>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-carousel
              :continuous="false"
              :cycle="cycle"
              :show-arrows="false"
              hide-delimiters
              v-model="model"
              :height="galleryHeight"
            >
              <v-carousel-item>
                <v-img src="../assets/gallery/barrier_3x_md.jpg" eager contain :max-height="galleryHeight"
                :srcset="require('../assets/gallery/barrier_3x_lg.jpg') + ' 3120w,' +
                        require('../assets/gallery/barrier_2x_lg.jpg') + ' 2080w,' +
                        require('../assets/gallery/barrier_3x_md.jpg') + ' 1800w,' +
                        require('../assets/gallery/barrier_2x_md.jpg') + ' 1200w,' +
                        require('../assets/gallery/barrier_1x_lg.jpg') + ' 1040w,' +
                        require('../assets/gallery/barrier_3x_sm.jpg') + ' 980w,' +
                        require('../assets/gallery/barrier_2x_sm.jpg') + ' 653w,' +
                        require('../assets/gallery/barrier_1x_md.jpg') + ' 600w,' +
                        require('../assets/gallery/barrier_1x_sm.jpg') + ' 327w,'" />
              </v-carousel-item>
              <v-carousel-item>
                <v-img src="../assets/gallery/crouch_3x_md.jpg" contain :max-height="galleryHeight"
                :srcset="require('../assets/gallery/crouch_3x_lg.jpg') + ' 1755w,' +
                        require('../assets/gallery/crouch_2x_lg.jpg') + ' 1170w,' +
                        require('../assets/gallery/crouch_3x_md.jpg') + ' 1012w,' +
                        require('../assets/gallery/crouch_2x_md.jpg') + ' 675w,' +
                        require('../assets/gallery/crouch_1x_lg.jpg') + ' 585w,' +                        
                        require('../assets/gallery/crouch_3x_sm.jpg') + ' 551w,' +
                        require('../assets/gallery/crouch_2x_sm.jpg') + ' 368w,' +
                        require('../assets/gallery/crouch_1x_md.jpg') + ' 338w,' +                        
                        require('../assets/gallery/crouch_1x_sm.jpg') + ' 184w,'" />
              </v-carousel-item>
              <v-carousel-item>
                <v-img src="../assets/gallery/flashlight_3x_md.jpg" contain :max-height="galleryHeight"
                :srcset="require('../assets/gallery/flashlight_3x_lg.jpg') + ' 1755w,' +
                        require('../assets/gallery/flashlight_2x_lg.jpg') + ' 1170w,' +
                        require('../assets/gallery/flashlight_3x_md.jpg') + ' 1012w,' +
                        require('../assets/gallery/flashlight_2x_md.jpg') + ' 675w,' +
                        require('../assets/gallery/flashlight_1x_lg.jpg') + ' 585w,' +
                        require('../assets/gallery/flashlight_3x_sm.jpg') + ' 551w,' +
                        require('../assets/gallery/flashlight_2x_sm.jpg') + ' 368w,' +
                        require('../assets/gallery/flashlight_1x_md.jpg') + ' 338w,' +
                        require('../assets/gallery/flashlight_1x_sm.jpg') + ' 184w,'" />
              </v-carousel-item>
              <v-carousel-item>
                <v-img src="../assets/gallery/team_3x_md.jpg" contain :max-height="galleryHeight"
                :srcset="require('../assets/gallery/team_3x_lg.jpg') + ' 1755w,' +
                        require('../assets/gallery/team_2x_lg.jpg') + ' 1170w,' +
                        require('../assets/gallery/team_3x_md.jpg') + ' 1012w,' +
                        require('../assets/gallery/team_2x_md.jpg') + ' 675w,' +
                        require('../assets/gallery/team_1x_lg.jpg') + ' 585w,' +
                        require('../assets/gallery/team_3x_sm.jpg') + ' 551w,' +
                        require('../assets/gallery/team_2x_sm.jpg') + ' 368w,' +
                        require('../assets/gallery/team_1x_md.jpg') + ' 338w,' +
                        require('../assets/gallery/team_1x_sm.jpg') + ' 184w,'" />
              </v-carousel-item>
              <v-carousel-item>
                <v-img src="../assets/gallery/mist_3x_md.jpg" contain :max-height="galleryHeight"
                :srcset="require('../assets/gallery/mist_3x_lg.jpg') + ' 1755w,' +
                        require('../assets/gallery/mist_2x_lg.jpg') + ' 1170w,' +
                        require('../assets/gallery/mist_3x_md.jpg') + ' 1012w,' +
                        require('../assets/gallery/mist_2x_md.jpg') + ' 675w,' +
                        require('../assets/gallery/mist_1x_lg.jpg') + ' 585w,' +
                        require('../assets/gallery/mist_3x_sm.jpg') + ' 551w,' +
                        require('../assets/gallery/mist_2x_sm.jpg') + ' 368w,' +
                        require('../assets/gallery/mist_1x_md.jpg') + ' 338w,' +
                        require('../assets/gallery/mist_1x_sm.jpg') + ' 184w,'" />
              </v-carousel-item>
              <v-carousel-item>
                <v-img src="../assets/gallery/point_mold_3x_md.jpg" contain :max-height="galleryHeight"
                :srcset="require('../assets/gallery/point_mold_3x_lg.jpg') + ' 1755w,' +
                        require('../assets/gallery/point_mold_2x_lg.jpg') + ' 1170w,' +
                        require('../assets/gallery/point_mold_3x_md.jpg') + ' 1012w,' +
                        require('../assets/gallery/point_mold_2x_md.jpg') + ' 675w,' +
                        require('../assets/gallery/point_mold_1x_lg.jpg') + ' 585w,' +
                        require('../assets/gallery/point_mold_3x_sm.jpg') + ' 551w,' +
                        require('../assets/gallery/point_mold_2x_sm.jpg') + ' 368w,' +
                        require('../assets/gallery/point_mold_1x_md.jpg') + ' 338w,' +
                        require('../assets/gallery/point_mold_1x_sm.jpg') + ' 184w,'" />
              </v-carousel-item>
              <v-carousel-item>
                <v-img src="../assets/gallery/sealed_3x_md.jpg" contain :max-height="galleryHeight"
                :srcset="require('../assets/gallery/sealed_3x_lg.jpg') + ' 3120w,' +
                        require('../assets/gallery/sealed_2x_lg.jpg') + ' 2080w,' +
                        require('../assets/gallery/sealed_3x_md.jpg') + ' 1800w,' +
                        require('../assets/gallery/sealed_2x_md.jpg') + ' 1200w,' +
                        require('../assets/gallery/sealed_1x_lg.jpg') + ' 1040w,' +
                        require('../assets/gallery/sealed_3x_sm.jpg') + ' 980w,' +
                        require('../assets/gallery/sealed_2x_sm.jpg') + ' 653w,' +
                        require('../assets/gallery/sealed_1x_md.jpg') + ' 600w,' +
                        require('../assets/gallery/sealed_1x_sm.jpg') + ' 327w,'" />
              </v-carousel-item>
              <v-carousel-item>
                <v-img src="../assets/gallery/sealed2_3x_md.jpg" contain :max-height="galleryHeight"
                :srcset="require('../assets/gallery/sealed2_3x_lg.jpg') + ' 3754w,' +
                        require('../assets/gallery/sealed2_2x_lg.jpg') + ' 2503w,' +
                        require('../assets/gallery/sealed2_3x_md.jpg') + ' 2166w,' +
                        require('../assets/gallery/sealed2_2x_md.jpg') + ' 1444w,' +
                        require('../assets/gallery/sealed2_1x_lg.jpg') + ' 1251w,' +
                        require('../assets/gallery/sealed2_3x_sm.jpg') + ' 1179w,' +
                        require('../assets/gallery/sealed2_2x_sm.jpg') + ' 786w,' +
                        require('../assets/gallery/sealed2_1x_md.jpg') + ' 722w,' +
                        require('../assets/gallery/sealed2_1x_sm.jpg') + ' 393w,'" />
              </v-carousel-item>
              <v-carousel-item>
                <v-img src="../assets/gallery/spraying_3x_md.jpg" contain :max-height="galleryHeight"
                :srcset="require('../assets/gallery/spraying_3x_lg.jpg') + ' 1844w,' +
                        require('../assets/gallery/spraying_2x_lg.jpg') + ' 1229w,' +
                        require('../assets/gallery/spraying_3x_md.jpg') + ' 1064w,' +
                        require('../assets/gallery/spraying_2x_md.jpg') + ' 709w,' +
                        require('../assets/gallery/spraying_1x_lg.jpg') + ' 615w,' +
                        require('../assets/gallery/spraying_3x_sm.jpg') + ' 579w,' +
                        require('../assets/gallery/spraying_2x_sm.jpg') + ' 386w,' +
                        require('../assets/gallery/spraying_1x_md.jpg') + ' 355w,' +
                        require('../assets/gallery/spraying_1x_sm.jpg') + ' 193w,'" />
              </v-carousel-item>
              <v-carousel-item>
                <v-img src="../assets/gallery/vehicles_3x_md.jpg" contain :max-height="galleryHeight"
                :srcset="require('../assets/gallery/vehicles_3x_lg.jpg') + ' 5229w,' +
                        require('../assets/gallery/vehicles_2x_lg.jpg') + ' 3486w,' +
                        require('../assets/gallery/vehicles_1x_md.jpg') + ' 3017w,' +
                        require('../assets/gallery/vehicles_3x_md.jpg') + ' 2011w,' +
                        require('../assets/gallery/vehicles_2x_lg.jpg') + ' 1743w,' +
                        require('../assets/gallery/vehicles_1x_sm.jpg') + ' 1642w,' +
                        require('../assets/gallery/vehicles_3x_sm.jpg') + ' 1095w,' +
                        require('../assets/gallery/vehicles_2x_md.jpg') + ' 1006w,' +
                        require('../assets/gallery/vehicles_1x_sm.jpg') + ' 547w,'" />
              </v-carousel-item>
            </v-carousel>
          </v-col>
        </v-row>
        <v-row no-gutters>
          <v-col align="center">
            <v-btn @click="model--" tile depressed class="secondary mr-2" :min-width="buttonMin" :min-height="buttonMin"><v-img contain src="../assets/arrow_left_white.svg" :max-width="buttonMax" /></v-btn>
            <v-btn @click="model++" tile depressed class="secondary ml-2" :min-width="buttonMin" :min-height="buttonMin"><v-img contain src="../assets/arrow_right_white.svg" :max-width="buttonMax" /></v-btn>
          </v-col>
        </v-row>
      </v-col>
      <v-col cols=2 class="hidden-sm-and-down">
      </v-col>
    </v-row>
    <br />
    <br />
    <br />
  </v-container>  
</template>

<script>
  export default {
    name: 'Gallery',
 
    data: () => ({
      cycle: false,  
      model: 0,
    }),
    computed: {
      buttonMin () {
        if (this.$vuetify.breakpoint.mdAndUp) { return "50" }
        else { return "25"}
      },
      buttonMax () {
        if (this.$vuetify.breakpoint.mdAndUp) { return "30" }
        else { return "20"}
      },
      galleryHeight () {
        if (this.$vuetify.breakpoint.xlOnly) { return "780" }
        else if (this.$vuetify.breakpoint.smAndUp) { return "450" }
        else { return "245"}
      },
    },
  }
</script>