<template>
  <v-app class="pa-0">
    <NavBar />
    <MainPage/>
  </v-app>
</template>

<script>
import NavBar from './components/NavBar';
import MainPage from './components/MainPage';

export default {
  name: 'App',

  components: {
    NavBar,    
    MainPage,
  },

  data: () => ({
    //
  }),
};
</script>

<style>
  html, body {
    overflow-x: hidden;
  }
  .offset-underline span {
    border-bottom: 1px solid currentColor;
    padding-bottom: 4px;
  }
</style>