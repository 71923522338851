var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"ma-0",attrs:{"id":"gallery","fluid":""}},[_c('v-row',[_c('v-col',{staticClass:"hidden-sm-and-down",attrs:{"cols":"2"}}),_c('v-col',{attrs:{"cols":"12","md":"8"}},[_c('v-row',[_c('v-col',{attrs:{"align":"center","justify":"center"}},[_c('p',{staticClass:"font-weight-medium text-h5 px-4 px-md-0 text-md-h4 text-lg-h3 primary--text"},[_vm._v("Gallery")])])],1),_c('v-row',[_c('v-col',[_c('v-carousel',{attrs:{"continuous":false,"cycle":_vm.cycle,"show-arrows":false,"hide-delimiters":"","height":_vm.galleryHeight},model:{value:(_vm.model),callback:function ($$v) {_vm.model=$$v},expression:"model"}},[_c('v-carousel-item',[_c('v-img',{attrs:{"src":require("../assets/gallery/barrier_3x_md.jpg"),"eager":"","contain":"","max-height":_vm.galleryHeight,"srcset":require('../assets/gallery/barrier_3x_lg.jpg') + ' 3120w,' +
                      require('../assets/gallery/barrier_2x_lg.jpg') + ' 2080w,' +
                      require('../assets/gallery/barrier_3x_md.jpg') + ' 1800w,' +
                      require('../assets/gallery/barrier_2x_md.jpg') + ' 1200w,' +
                      require('../assets/gallery/barrier_1x_lg.jpg') + ' 1040w,' +
                      require('../assets/gallery/barrier_3x_sm.jpg') + ' 980w,' +
                      require('../assets/gallery/barrier_2x_sm.jpg') + ' 653w,' +
                      require('../assets/gallery/barrier_1x_md.jpg') + ' 600w,' +
                      require('../assets/gallery/barrier_1x_sm.jpg') + ' 327w,'}})],1),_c('v-carousel-item',[_c('v-img',{attrs:{"src":require("../assets/gallery/crouch_3x_md.jpg"),"contain":"","max-height":_vm.galleryHeight,"srcset":require('../assets/gallery/crouch_3x_lg.jpg') + ' 1755w,' +
                      require('../assets/gallery/crouch_2x_lg.jpg') + ' 1170w,' +
                      require('../assets/gallery/crouch_3x_md.jpg') + ' 1012w,' +
                      require('../assets/gallery/crouch_2x_md.jpg') + ' 675w,' +
                      require('../assets/gallery/crouch_1x_lg.jpg') + ' 585w,' +                        
                      require('../assets/gallery/crouch_3x_sm.jpg') + ' 551w,' +
                      require('../assets/gallery/crouch_2x_sm.jpg') + ' 368w,' +
                      require('../assets/gallery/crouch_1x_md.jpg') + ' 338w,' +                        
                      require('../assets/gallery/crouch_1x_sm.jpg') + ' 184w,'}})],1),_c('v-carousel-item',[_c('v-img',{attrs:{"src":require("../assets/gallery/flashlight_3x_md.jpg"),"contain":"","max-height":_vm.galleryHeight,"srcset":require('../assets/gallery/flashlight_3x_lg.jpg') + ' 1755w,' +
                      require('../assets/gallery/flashlight_2x_lg.jpg') + ' 1170w,' +
                      require('../assets/gallery/flashlight_3x_md.jpg') + ' 1012w,' +
                      require('../assets/gallery/flashlight_2x_md.jpg') + ' 675w,' +
                      require('../assets/gallery/flashlight_1x_lg.jpg') + ' 585w,' +
                      require('../assets/gallery/flashlight_3x_sm.jpg') + ' 551w,' +
                      require('../assets/gallery/flashlight_2x_sm.jpg') + ' 368w,' +
                      require('../assets/gallery/flashlight_1x_md.jpg') + ' 338w,' +
                      require('../assets/gallery/flashlight_1x_sm.jpg') + ' 184w,'}})],1),_c('v-carousel-item',[_c('v-img',{attrs:{"src":require("../assets/gallery/team_3x_md.jpg"),"contain":"","max-height":_vm.galleryHeight,"srcset":require('../assets/gallery/team_3x_lg.jpg') + ' 1755w,' +
                      require('../assets/gallery/team_2x_lg.jpg') + ' 1170w,' +
                      require('../assets/gallery/team_3x_md.jpg') + ' 1012w,' +
                      require('../assets/gallery/team_2x_md.jpg') + ' 675w,' +
                      require('../assets/gallery/team_1x_lg.jpg') + ' 585w,' +
                      require('../assets/gallery/team_3x_sm.jpg') + ' 551w,' +
                      require('../assets/gallery/team_2x_sm.jpg') + ' 368w,' +
                      require('../assets/gallery/team_1x_md.jpg') + ' 338w,' +
                      require('../assets/gallery/team_1x_sm.jpg') + ' 184w,'}})],1),_c('v-carousel-item',[_c('v-img',{attrs:{"src":require("../assets/gallery/mist_3x_md.jpg"),"contain":"","max-height":_vm.galleryHeight,"srcset":require('../assets/gallery/mist_3x_lg.jpg') + ' 1755w,' +
                      require('../assets/gallery/mist_2x_lg.jpg') + ' 1170w,' +
                      require('../assets/gallery/mist_3x_md.jpg') + ' 1012w,' +
                      require('../assets/gallery/mist_2x_md.jpg') + ' 675w,' +
                      require('../assets/gallery/mist_1x_lg.jpg') + ' 585w,' +
                      require('../assets/gallery/mist_3x_sm.jpg') + ' 551w,' +
                      require('../assets/gallery/mist_2x_sm.jpg') + ' 368w,' +
                      require('../assets/gallery/mist_1x_md.jpg') + ' 338w,' +
                      require('../assets/gallery/mist_1x_sm.jpg') + ' 184w,'}})],1),_c('v-carousel-item',[_c('v-img',{attrs:{"src":require("../assets/gallery/point_mold_3x_md.jpg"),"contain":"","max-height":_vm.galleryHeight,"srcset":require('../assets/gallery/point_mold_3x_lg.jpg') + ' 1755w,' +
                      require('../assets/gallery/point_mold_2x_lg.jpg') + ' 1170w,' +
                      require('../assets/gallery/point_mold_3x_md.jpg') + ' 1012w,' +
                      require('../assets/gallery/point_mold_2x_md.jpg') + ' 675w,' +
                      require('../assets/gallery/point_mold_1x_lg.jpg') + ' 585w,' +
                      require('../assets/gallery/point_mold_3x_sm.jpg') + ' 551w,' +
                      require('../assets/gallery/point_mold_2x_sm.jpg') + ' 368w,' +
                      require('../assets/gallery/point_mold_1x_md.jpg') + ' 338w,' +
                      require('../assets/gallery/point_mold_1x_sm.jpg') + ' 184w,'}})],1),_c('v-carousel-item',[_c('v-img',{attrs:{"src":require("../assets/gallery/sealed_3x_md.jpg"),"contain":"","max-height":_vm.galleryHeight,"srcset":require('../assets/gallery/sealed_3x_lg.jpg') + ' 3120w,' +
                      require('../assets/gallery/sealed_2x_lg.jpg') + ' 2080w,' +
                      require('../assets/gallery/sealed_3x_md.jpg') + ' 1800w,' +
                      require('../assets/gallery/sealed_2x_md.jpg') + ' 1200w,' +
                      require('../assets/gallery/sealed_1x_lg.jpg') + ' 1040w,' +
                      require('../assets/gallery/sealed_3x_sm.jpg') + ' 980w,' +
                      require('../assets/gallery/sealed_2x_sm.jpg') + ' 653w,' +
                      require('../assets/gallery/sealed_1x_md.jpg') + ' 600w,' +
                      require('../assets/gallery/sealed_1x_sm.jpg') + ' 327w,'}})],1),_c('v-carousel-item',[_c('v-img',{attrs:{"src":require("../assets/gallery/sealed2_3x_md.jpg"),"contain":"","max-height":_vm.galleryHeight,"srcset":require('../assets/gallery/sealed2_3x_lg.jpg') + ' 3754w,' +
                      require('../assets/gallery/sealed2_2x_lg.jpg') + ' 2503w,' +
                      require('../assets/gallery/sealed2_3x_md.jpg') + ' 2166w,' +
                      require('../assets/gallery/sealed2_2x_md.jpg') + ' 1444w,' +
                      require('../assets/gallery/sealed2_1x_lg.jpg') + ' 1251w,' +
                      require('../assets/gallery/sealed2_3x_sm.jpg') + ' 1179w,' +
                      require('../assets/gallery/sealed2_2x_sm.jpg') + ' 786w,' +
                      require('../assets/gallery/sealed2_1x_md.jpg') + ' 722w,' +
                      require('../assets/gallery/sealed2_1x_sm.jpg') + ' 393w,'}})],1),_c('v-carousel-item',[_c('v-img',{attrs:{"src":require("../assets/gallery/spraying_3x_md.jpg"),"contain":"","max-height":_vm.galleryHeight,"srcset":require('../assets/gallery/spraying_3x_lg.jpg') + ' 1844w,' +
                      require('../assets/gallery/spraying_2x_lg.jpg') + ' 1229w,' +
                      require('../assets/gallery/spraying_3x_md.jpg') + ' 1064w,' +
                      require('../assets/gallery/spraying_2x_md.jpg') + ' 709w,' +
                      require('../assets/gallery/spraying_1x_lg.jpg') + ' 615w,' +
                      require('../assets/gallery/spraying_3x_sm.jpg') + ' 579w,' +
                      require('../assets/gallery/spraying_2x_sm.jpg') + ' 386w,' +
                      require('../assets/gallery/spraying_1x_md.jpg') + ' 355w,' +
                      require('../assets/gallery/spraying_1x_sm.jpg') + ' 193w,'}})],1),_c('v-carousel-item',[_c('v-img',{attrs:{"src":require("../assets/gallery/vehicles_3x_md.jpg"),"contain":"","max-height":_vm.galleryHeight,"srcset":require('../assets/gallery/vehicles_3x_lg.jpg') + ' 5229w,' +
                      require('../assets/gallery/vehicles_2x_lg.jpg') + ' 3486w,' +
                      require('../assets/gallery/vehicles_1x_md.jpg') + ' 3017w,' +
                      require('../assets/gallery/vehicles_3x_md.jpg') + ' 2011w,' +
                      require('../assets/gallery/vehicles_2x_lg.jpg') + ' 1743w,' +
                      require('../assets/gallery/vehicles_1x_sm.jpg') + ' 1642w,' +
                      require('../assets/gallery/vehicles_3x_sm.jpg') + ' 1095w,' +
                      require('../assets/gallery/vehicles_2x_md.jpg') + ' 1006w,' +
                      require('../assets/gallery/vehicles_1x_sm.jpg') + ' 547w,'}})],1)],1)],1)],1),_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"align":"center"}},[_c('v-btn',{staticClass:"secondary mr-2",attrs:{"tile":"","depressed":"","min-width":_vm.buttonMin,"min-height":_vm.buttonMin},on:{"click":function($event){_vm.model--}}},[_c('v-img',{attrs:{"contain":"","src":require("../assets/arrow_left_white.svg"),"max-width":_vm.buttonMax}})],1),_c('v-btn',{staticClass:"secondary ml-2",attrs:{"tile":"","depressed":"","min-width":_vm.buttonMin,"min-height":_vm.buttonMin},on:{"click":function($event){_vm.model++}}},[_c('v-img',{attrs:{"contain":"","src":require("../assets/arrow_right_white.svg"),"max-width":_vm.buttonMax}})],1)],1)],1)],1),_c('v-col',{staticClass:"hidden-sm-and-down",attrs:{"cols":"2"}})],1),_c('br'),_c('br'),_c('br')],1)}
var staticRenderFns = []

export { render, staticRenderFns }