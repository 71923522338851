<template>
  <v-container id="howitworks" class="ma-0 pa-0" fluid>
    <v-row no-gutters>
      <v-col cols=2 class="hidden-sm-and-down">
      </v-col>
      <v-col cols=12 md=10 class="py-0 pb-md-3 px-4 px-sm-6 px-lg-13">      
      <p class="font-weight-medium text-h5 text-md-h4 text-lg-h3 primary--text">How it Works</p>
      <p class="pt-0 pt-md-8 mb-0 font-weight-medium text-h6 text-md-h5 primary--text">First Steps</p>
      </v-col>
    </v-row>    
    <v-row no-gutters class="mt-3 mt-md-4">
      <v-col cols=2 class="hidden-sm-and-down"></v-col>
      <v-col cols=12 sm=6 md=5 class="px-2 px-sm-4 px-lg-11">
        <v-timeline dense clipped align-top id="mobileTimeline">
          <v-timeline-item fill-dot color="white" elevation="0">
            <template v-slot:icon>
              <v-card flat>
                <v-card-text class="pa-2 secondary white--text font-weight-bold text-none">
                  01
                </v-card-text>
             </v-card>
            </template>
            <p class="pl-2 pl-md-11 text-subtitle-2 text-md-subtitle-1 font-weight-bold">Professional Mold Inspection</p>
            <p class="pl-2 pl-md-11 pr-4 pr-md-0 text-subtitle-2 text-md-subtitle-1 font-weight-regular">The inspector will look for visible signs of mold as well as evidence of a recent or ongoing source of moisture.</p>
          </v-timeline-item>
          <v-timeline-item fill-dot color="white" elevation="0">
            <template v-slot:icon>
              <v-card flat>
                <v-card-text class="pa-2 secondary white--text font-weight-bold text-none">
                  02
                </v-card-text>
             </v-card>
            </template>
            <p class="pl-2 pl-md-11 text-subtitle-2 text-md-subtitle-1 font-weight-bold">Mold Sampling / Testing</p>
            <p class="pl-2 pl-md-11 pr-4 pr-md-0 text-subtitle-2 text-md-subtitle-1 font-weight-regular">Once the presence of mold is established, the inspector will determine whether mold sampling and laboratory testing are needed.</p>
            <v-container class="pl-2 pl-md-11">
              <HowItWorksListItem v-for="item in step2_items" :key="item.title" :title="item.title" />         
            </v-container>
          </v-timeline-item>          
        </v-timeline>
      </v-col>
      <v-col cols=12 sm=6 md=5 class="pt-5 pt-md-0">
        <v-img contain eager class="mr-sm-6 mr-lg-13 px-4 px-sm-0" src="../assets/images/inspection_3x_md.jpg"
               :srcset="require('../assets/images/inspection_3x_lg.jpg') + ' 1902w,' +
                        require('../assets/images/inspection_2x_lg.jpg') + ' 1268w,' +
                        require('../assets/images/inspection_3x_sm.jpg') + ' 870w,' +
                        require('../assets/images/inspection_3x_md.jpg') + ' 834w,' +
                        require('../assets/images/inspection_1x_lg.jpg') + ' 634w,' +
                        require('../assets/images/inspection_2x_sm.jpg') + ' 580w,' +
                        require('../assets/images/inspection_2x_md.jpg') + ' 556w,' +
                        require('../assets/images/inspection_1x_sm.jpg') + ' 290w,' +
                        require('../assets/images/inspection_1x_md.jpg') + ' 278w,'" />
      </v-col>
    </v-row>
   <v-row no-gutters>
      <v-col cols=2 class="hidden-sm-and-down">
      </v-col>
      <v-col cols=10 class="px-4 px-sm-6 px-lg-13">      
      <p class="pt-0 pt-md-8 mb-0 font-weight-medium text-h6 text-md-h5 primary--text">Process</p>
      </v-col>
      <v-spacer />
    </v-row>    
    <v-row no-gutters class="mt-3 mt-md-4">
      <v-col cols=2 class="hidden-sm-and-down"></v-col>
      <v-col cols=12 sm=6 md=5 class="px-2 px-sm-4 px-lg-11">
        <v-timeline dense clipped align-top>
          <v-timeline-item fill-dot color="white" elevation="0">
            <template v-slot:icon>
              <v-card flat>
                <v-card-text class="pa-2 secondary white--text font-weight-bold text-none">
                  03
                </v-card-text>
             </v-card>
            </template>
            <p class="pl-2 pl-md-11 text-subtitle-2 text-md-subtitle-1 font-weight-bold">Mold Remediation</p>
            <p class="pl-2 pl-md-11 pr-4 pr-md-0 text-subtitle-2 text-md-subtitle-1 font-weight-regular">When performing mold remediation, we strictly follow the guidelines of the IICRC. While the mold remediation itself can take many forms, depending on the circumstances, typical mold remediation includes:</p>
            <v-container class="pl-2 pl-md-11">
              <HowItWorksListItem v-for="item in step3_items" :key="item.title" :title="item.title" />         
            </v-container>            
          </v-timeline-item>
          <v-timeline-item fill-dot color="white" elevation="0">
            <template v-slot:icon>
              <v-card flat>
                <v-card-text class="pa-2 secondary white--text font-weight-bold text-none">
                  04
                </v-card-text>
             </v-card>
            </template>
            <p class="pl-2 pl-md-11 text-subtitle-2 text-md-subtitle-1 font-weight-bold">Final Mold Clearance Testing</p>
          </v-timeline-item>          
        </v-timeline>
      </v-col>
      <v-col cols=12 sm=6 md=5 class="pt-5 pt-md-0 px-4 px-sm-0">
        <v-img contain eager class="mr-sm-6 mr-lg-13" src="../assets/images/consultation_3x_md.jpg"
               :srcset="require('../assets/images/consultation_3x_lg.jpg') + ' 1902w,' +
                        require('../assets/images/consultation_2x_lg.jpg') + ' 1268w,' +
                        require('../assets/images/consultation_3x_sm.jpg') + ' 870w,' +
                        require('../assets/images/consultation_3x_md.jpg') + ' 834w,' +
                        require('../assets/images/consultation_1x_lg.jpg') + ' 634w,' +
                        require('../assets/images/consultation_2x_sm.jpg') + ' 580w,' +
                        require('../assets/images/consultation_2x_md.jpg') + ' 556w,' +
                        require('../assets/images/consultation_1x_sm.jpg') + ' 290w,' +
                        require('../assets/images/consultation_1x_md.jpg') + ' 278w,'" />
      </v-col>
    </v-row>
    <v-row no-gutters>
      <v-col cols=2 class="hidden-sm-and-down">
      </v-col>
      <v-col cols=12 md=10 class="px-4 px-sm-6 px-lg-13">      
      <p class="pt-3 pt-md-5 font-weight-medium text-h6 text-md-h5 primary--text">Get Help Now</p>
      <p class="text-subtitle-2 text-md-subtitle-1 font-weight-regular">Call us today to discuss mold remediation services with our mold experts</p>
      <v-container class="ma-0 pa-0">
          <v-row class="ma-0 mt-5">
            <v-col class="ma-0 pa-0">
              <v-btn x-large depressed tile class="px-8 py-5 px-md-12 py-md-8 secondary white--text font-weight-medium text-none" @click="$vuetify.goTo('#contactus')">Contact us</v-btn>
            </v-col>
          </v-row>
        </v-container>
      </v-col>
    </v-row>        
  </v-container>
</template>

<script>
  import HowItWorksListItem from './HowItWorksListItem'; 
  export default {
    components: {
      HowItWorksListItem,
    },
    name: 'HowItWorks',
    data: () => ({
      step2_items: [
        { title: "<strong>Mold sampling</strong> allows us to determine if the levels of mold are unsafe." },
        { title: "<strong>Mold testing</strong> helps us determine toxicity levels and the dangers of exposure." },
      ],
      step3_items: [
        { title: "Site assessment and moisture mitigation to address the cause of mold growth" },
        { title: "Site preparation and containment to prevent cross-contamination" },
        { title: "Structural drying and dehumidifying" },
        { title: "Mold removal and cleaning - which may include HEPA vacuuming, scrubbing, sanding, and surface disinfection" },
        { title: "Safe disposal of any mold-damaged materials or contents" },
        { title: "Repair and / or reconstruction, where necessary" },
        { title: "Sealing and / or encapsulation to help prevent future mold problems" },
      ]
    }),
  }
</script>

<style scoped>
  div >>> .v-timeline-item__dot {
    box-shadow: none;
  }
  div >>> .v-timeline:before {
    background: #004dcb
  }
  div >>> .v-timeline {
    padding-top: 0px;
  }
</style>