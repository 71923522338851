<template>
  <v-container id="moldtypes" class="ma-0 pa-0" fluid>
    <v-row no-gutters>
      <v-col cols=2 class="hidden-sm-and-down">
      </v-col>
      <v-col cols=5 class="px-4 px-sm-6 px-lg-13">      
      <p class="font-weight-medium text-h5 text-md-h4 text-lg-h3 primary--text">Mold Types</p>
      <p class="font-weight-regular text-body-2 text-md-body-1 primary--text">Mold is very <strong>dangerous</strong> for our health. Below you can find the most common types of mold and what effect it has on the human body:</p>
      </v-col>
      <v-spacer />
    </v-row>

    <v-row no-gutters>
      <v-col cols=2 class="hidden-sm-and-down">
      </v-col>
      <v-col cols=9 class="px-4 px-sm-6 px-lg-13 text-body-2 text-md-body-1 font-weight-bold">
        <v-container class="px-0">
          <v-row no-gutters class="mx-0">
            <v-col :class="showVerticalBar">
              <v-divider vertical align="left" role="presentation" class="secondary" />
            </v-col>
            <v-col class="px-4 px-sm-6 text-body-2 text-md-body-1 font-weight-bold">
              Stachybotrys, Toxic Black Mold
            </v-col>
          </v-row>
        </v-container>
      </v-col>
      <v-spacer />
    </v-row>
    <v-row no-gutters>
      <v-col cols=2 class="hidden-sm-and-down">
      </v-col>
      <v-col cols=9 md=7 class="px-4 px-sm-6 px-lg-13 pt-0 text-body-2 text-md-body-1 font-weight-regular">
        <v-container class="pa-0 ma-0">
          <v-row class="mx-0" no-gutters>
            <v-col :class="showVerticalBar"></v-col>
            <v-col class="py-0 px-4 px-sm-6 mt-0 text-body-2 text-md-body-1">
              <div class="font-italic mb-2 mb-sm-4 mb-md-5">Requires live, active water intrusion to commence growth.</div>
              <strong>Symptoms</strong> produced by Stachybotrys range beyond allergy-type symptoms to dermatitis, rhinitis, confusion, dizziness and disorientation, anxiety, depression, aggression, shaking, seizures, and an itching or burning sensation in the mouth, throat, nasal passages, and eyes. 
              It is known to produce severe brain fog and mental impairment.
            </v-col>
          </v-row>
        </v-container>
      </v-col>
      <v-col cols=3>
        <v-img contain class="mr-sm-6 mr-lg-13" :width="moldImageWidth" position="right" src="../assets/stachybotrys.png" />
      </v-col>
    </v-row>

    <v-row no-gutters>
      <v-col cols=2 class="hidden-sm-and-down">
      </v-col>
      <v-col cols=9 class="px-4 px-sm-6 px-lg-13 text-body-2 text-md-body-1 font-weight-bold">
        <v-container class="px-0">
          <v-row no-gutters class="mx-0">
            <v-col :class="showVerticalBar">
              <v-divider vertical align="left" role="presentation" class="secondary" />
            </v-col>
            <v-col class="px-4 px-sm-6 text-body-2 text-md-body-1 font-weight-bold">
              Penicillium / Aspergillus
            </v-col>
          </v-row>
        </v-container>
      </v-col>
      <v-spacer />
    </v-row>
    <v-row no-gutters>
      <v-col cols=2 class="hidden-sm-and-down">
      </v-col>
      <v-col cols=9 md=7 class="px-4 px-sm-6 px-lg-13 pt-0 text-body-2 text-md-body-1 font-weight-regular">
        <v-container class="pa-0 ma-0">
          <v-row class="mx-0" no-gutters>
            <v-col :class="showVerticalBar"></v-col>
            <v-col class="py-0 px-4 px-sm-6 mt-0 text-body-2 text-md-body-1">
              <div class="font-italic mb-2 mb-sm-4 mb-md-5">Can thrive either in dry conditions or with moderate humidity.
The human impact from this mold may include allergy-type.</div>
              <strong>Symptoms</strong> such as hay fever and asthma, hypersensitivity pneumonitis, and various respiratory irritations and infections. It is most commonly found growing on household dust, wallpaper and wallpaper glue, fabrics (including carpet), and moist building materials. Colonies may range in color from grey to white, plus various shades of blue or green.
            </v-col>
          </v-row>
        </v-container>
      </v-col>
      <v-col cols=3>
        <v-img contain class="mr-sm-6 mr-lg-13" :width="moldImageWidth" position="right" src="../assets/penicillium.png" />
      </v-col>
    </v-row>

    <v-row no-gutters>
      <v-col cols=2 class="hidden-sm-and-down">
      </v-col>
      <v-col cols=9 class="px-4 px-sm-6 px-lg-13 text-body-2 text-md-body-1 font-weight-bold">
        <v-container class="px-0">
          <v-row no-gutters class="mx-0">
            <v-col :class="showVerticalBar">
              <v-divider vertical align="left" role="presentation" class="secondary" />
            </v-col>
            <v-col class="px-4 px-sm-6 text-body-2 text-md-body-1 font-weight-bold">
              Chaetomium
            </v-col>
          </v-row>
        </v-container>
      </v-col>
      <v-spacer />
    </v-row>
    <v-row no-gutters>
      <v-col cols=2 class="hidden-sm-and-down">
      </v-col>
      <v-col cols=9 md=7 class="px-4 px-sm-6 px-lg-13 pt-0 text-body-2 text-md-body-1 font-weight-regular">
        <v-container class="pa-0 ma-0">
          <v-row class="mx-0" no-gutters>
            <v-col :class="showVerticalBar"></v-col>
            <v-col class="py-0 px-4 px-sm-6 mt-0 text-body-2 text-md-body-1">
              <div class="font-italic mb-2 mb-sm-4 mb-md-5">Colonies may range in color from white, to olive green, to brown, to black.</div>
              <strong>Symptoms</strong> such as dry skin, hives and postnasal drip, burning sensation in the mouth, throat, nasal passages and eyes. Cladosporium will grow on many substrates, including textiles, wood and moist window sills (check windows for interior condensation), and has a wide temperature-range tolerance.
            </v-col>
          </v-row>
        </v-container>
      </v-col>
      <v-col cols=3>
        <v-img contain class="mr-sm-6 mr-lg-13" :width="moldImageWidth" position="right" src="../assets/chaetomium.png" />
      </v-col>
    </v-row>     
  </v-container>
</template>

<script>
  export default {
    name: 'MoldTypes',
    data: () => ({
      //
    }),
    computed: {
      showVerticalBar () {
        if (this.$vuetify.breakpoint.width < 768) { return "d-none" }
        else { return "d-flex flex-grow-0 flex-shrink-1"}
      },
      moldImageWidth () {
        if (this.$vuetify.breakpoint.lgAndUp) { return "165" }
        else { return "94"}
      },  
    }
  }
</script>