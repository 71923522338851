<template>
  <v-container id="reviews" class="ma-0 pa-0" fluid>
    <v-row no-gutters>
      <v-col cols=2 class="hidden-sm-and-down">
      </v-col>
      <v-col cols=12 md=8 class="pb-0 pb-md-4 px-4 px-sm-6 px-lg-13"> 
        <p class="font-weight-medium text-h5 text-md-h4 text-lg-h3 primary--text">Reviews</p>
        <v-card outlined tile class="mt-7">
          <v-container>
            <v-row class="review" no-gutters>
              <v-col class="pa-7">
                <v-carousel
                  :continuous="false"
                  :cycle="cycle"
                  :show-arrows="false"
                  hide-delimiters
                  v-model="model"
                  height="auto"
                  light
                >
                  <v-carousel-item
                    v-for="(item,i) in reviews"
                    :key="i">
                    <v-sheet>
                      <v-row class="pa-0 ma-0" no-gutters><v-col class="flex-grow-0 flex-shrink-1 align-center"><p class="text-subtitle-1 text-md-h6 font-weight-bold mb-0">{{ item.rating }}</p></v-col><v-col class="pa-0 ma-0 ml-2 d-flex align-center"><v-img class="pa-0 ma-0" v-for="n in item.ratingint" :key="n" contain src="../assets/star.svg" :max-width="starHeightWidth" :max-height="starHeightWidth" /></v-col></v-row>
                      <p class="mt-8 text-subtitle-2 text-md-subtitle-2 font-weight-bold">{{ item.title }}</p>
                      <p class="mt-4 mb-0 text-subtitle-2 text-md-subtitle-2 font-weight-regular">{{ item.body }}</p>
                      <p class="mb-0 pt-6 pb-0 text-subtitle-1 text-md-h6 font-weight-medium">{{ item.name }}</p>
                      <p class="mt-0 pt-1 mb-0 text-subtitle-2 text-md-subtitle-1 grey--text lighten-3">{{ item.date }}</p>
                    </v-sheet>
                  </v-carousel-item>
                </v-carousel>
              </v-col>
            </v-row>
          </v-container>
        </v-card>
                    
        <v-pagination
          v-model="model"
          :length="reviews.length"
          total-visible="6"
          prev-icon="mdi-arrow-left"
          next-icon="mdi-arrow-right"
          class="mt-8 font-weight-medium"
        >
        </v-pagination>
      </v-col>
      <v-col cols=2 class="hidden-sm-and-down">
      </v-col>
    </v-row>   
  </v-container>
</template>

<script>
  export default {
    name: 'Reviews',
    data: () => ({
      reviews: [
        { 
          rating: "5.0", 
          ratingint: 5,
          title: "Very punctual",
          body: "This company is very punctual and very friendly and do their job great! I recommend.",
          name: "Ovik Zhamgaryan",
          date: "2/5/2021"
        }, 
        { 
          rating: "5.0", 
          ratingint: 5,
          title: "Hard working",
          body: "Very hard working team, professionals, very organized, clean, punctual. Did more work then was expected in the beginning. Was willing to adjust to a new situation, worked long hours just to finish as promised. Thank you very much Lana! If you want professionals I recommend Mold Experts of Atlanta.",
          name: "Dina D.",
          date: "1/29/2021"
        },
        { 
          rating: "5.0", 
          ratingint: 5,
          title: "Task: Eliminate Toxic Materials or Mold",
          body: "I called a few places to come remediate mold under my stairs. They had 2-3 week waits. I finally went on Angie's List who paired me with Mold Experts of Atlanta LLC. They were able to come out within a few hours and even finish the work that day. I was very impressed with the customer service, work, and how friendly the contractor was. 10/10 would hire again.",
          name: "Erin B.",
          date: "10/15/2020"
        },
        { 
          rating: "5.0", 
          ratingint: 5,
          title: "Wonderful experience",
          body: "They did a great job, and quickly. Also offered me important advice on additional work - found other evidence of mold that need to be removed. I will hire them to do that additional work. Really nice people. It did cost - not sure what a competitive cost would be. But it was worth it to get the black mold removed quickly.",
          name: "Esther S.",
          date: "10/14/2020"
        },
        { 
          rating: "5.0", 
          ratingint: 5,
          title: "Task: Eliminate Toxic Materials or Mold",
          body: "They were fast and efficient. Out of several estimates I received, Mold Experts was the most affordable.",
          name: "Sandy M.",
          date: "10/14/2020"
        },
        { 
          rating: "5.0", 
          ratingint: 5,
          title: "Task: Test for Toxic Materials & Mold",
          body: "I recommend this service to anyone in need of mold testing in their home. The customer service was amazing. They were very informative and attentive to me and my family's needs and had our best interest at heart especially with us having small children. Best service. Will absolutely recommend their service.",
          name: "Keiara F.",
          date: "10/13/2020"
        },    
        { 
          rating: "5.0", 
          ratingint: 5,
          title: "Excellent explanations",
          body: "Excellent explanations each step of the process. Very thorough and pleasant to work with.",
          name: "Sharon C.",
          date: "8/17/2020"
        },
        { 
          rating: "5.0", 
          ratingint: 5,
          title: "Would use again",
          body: "Will use again if needed! Very impressed.",
          name: "Jesse R.",
          date: "7/17/2020"
        },
        { 
          rating: "5.0", 
          ratingint: 5,
          title: "Task: Eliminate Toxic Materials or Mold",
          body: "WOW! Tony did a fantastic job for us, going over and above teh quote, even helped us move some heavy furniture out to get to the mold. If you have mold to remove, he is the Man to Call!",
          name: "Jan S.",
          date: "7/14/2020"
        },
        { 
          rating: "5.0", 
          ratingint: 5,
          title: "Very professional",
          body: "Great quality mold inspection and mold removal performed. Very professional work. Excellent Customer service. Recomend to everyone who prefers professionals.",
          name: "Svetlana R.",
          date: "6/9/2020"
        },          
        
      ],
      model: 1,
      cycle: false,
    }),
    computed: {
      starHeightWidth () {
        if (this.$vuetify.breakpoint.mdAndUp) { return "22" }
        else if (this.$vuetify.breakpoint.mdAndUp) { return "16" }
        else { return "14"}
      },
    }
  }
</script>


<style scoped>
  div >>> .theme--light.v-sheet--outlined {
    border-top-color: #004dcb;
    border-bottom-color: #004dcb;
    border-left-color: #004dcb;
    border-right-color: #004dcb;
    border-width: 3px;
  }
  div >>> .theme--light.v-icon {
    color: #004dcb;
  }
  div >>> .v-pagination__item {
    box-shadow: none !important;
    border-width: 1px !important;
    border-style: solid !important;
    border-radius: 0px !important;
    border-top-color: #004dcb !important;
    border-bottom-color: #004dcb !important;
    border-left-color: #004dcb !important;
    border-right-color: #004dcb !important;
  }
  div >>> .v-pagination__navigation {
    box-shadow: none !important;
    border-width: 0px !important;
    color: #004dcb;
  }
  div >>> .v-pagination__item--active {
    background-color: #004dcb !important;
  } 
</style>