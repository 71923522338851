<template>
  <svg xmlns="http://www.w3.org/2000/svg" 
    :width="width" 
    :height="height" 
    :aria-labelledby="iconName" 
    role="presentation"
    viewBox="0 0 324 267">
    <title>{{ iconName }}</title>
    <g>
      <path class="cls-1" :fill="iconColor" :stroke="iconColor" stroke-miterlimit="10" stroke-width="15" d="M74.48,116.79a214.72,214.72,0,0,0,93.73,93.73l31.29-31.29a14.26,14.26,0,0,1,14.5-3.56,161.67,161.67,0,0,0,50.78,8.11A14.22,14.22,0,0,1,279,198v49.78A14.22,14.22,0,0,1,264.78,262C131.25,262,23,153.75,23,20.22A14.22,14.22,0,0,1,37.22,6H87a14.22,14.22,0,0,1,14.22,14.22A161.67,161.67,0,0,0,109.33,71a14.26,14.26,0,0,1-3.56,14.5Z"/>
    </g>
  </svg>
</template>

<script>
export default {
  props: {
    iconName: {
      type: String,
      default: 'box'
    },
    width: {
      type: [Number, String],
      default: 18
    },
    height: {
      type: [Number, String],
      default: 18
    },
    iconColor: {
      type: String,
      default: 'currentColor'
    },
  }
}
</script>

<style scoped>
svg {
  display: inline-block;
}
</style>