<template>
  <v-row>
    <v-col cols=1>
      <v-icon class="secondary--text">mdi-square-medium</v-icon>
    </v-col>
    <v-col><span class="text-subtitle-2 text-md-subtitle-1 font-weight-regular" v-html="title"></span></v-col>
  </v-row>
</template>

<script>
  export default {
    name: 'HowItWorksListItem',
    data: () => ({
      //
    }),
     props: {
        title: String,
     }   
  }
</script>