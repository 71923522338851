<template>
    <v-col cols="12" sm="4" class="font-weight-regular text-subtitle-2 text-md-subtitle-1 primary--text pa-0 pa-md-4 ml-1 ml-md-0">
        <v-container class="ma-0 pl-0">
          <v-row>
            <v-col cols=1>
              <v-icon class="secondary--text">mdi-square-medium</v-icon>
            </v-col>
            <v-col class="ml-1">{{ text }}</v-col>
        </v-row>
        </v-container>
    </v-col>
</template>

<script>
  export default {
    name: 'WhyUsReasons',
    data: () => ({
        //
    }),
    props: {
        text: String,
    },        
  }
</script>