<template>
  <v-container id="servicearea" class="ma-0" fluid>
    <v-row>
      <v-col cols=2 class="hidden-sm-and-down">
      </v-col>
      <v-col cols=12 md=5 class="pb-0 pb-md-4 px-4 px-sm-6 px-lg-13">      
      <p class="font-weight-medium text-h5 text-md-h4 text-lg-h3 primary--text">Service Area</p>
      <p class="font-weight-regular text-body-2 text-md-body-1">Our mold removal and mold remediation service area includes the Atlanta Metro area. You can find the locations we serve below.</p>
      <v-btn depressed elevation=0 class="pa-0 px-0 ma-0 font-weight-medium text-none white secondary--text offset-underline" @click="$vuetify.goTo('#contactus')">Contact us</v-btn>
      </v-col>
      <v-spacer />
    </v-row>
    <v-row>
      <v-col cols=2 class="hidden-sm-and-down">
      </v-col>
      <v-col cols=12 md=10 class="pt-4 pt-md-8 px-4 px-sm-6 px-lg-13">
      <v-container class="ma-0 pl-4 pl-md-0">
        <v-row>        
          <ServiceAreasTable v-for="item in serviceAreas" :key="item.title" :text="item.title" />
        </v-row>
      </v-container>
      </v-col>
    </v-row>      
  </v-container>
</template>

<script>
  import ServiceAreasTable from './ServiceAreasTable'; 
  export default {
    components: {
      ServiceAreasTable,
    },  
    name: 'ServiceArea',
    data: () => ({
      serviceAreas: [
        { title: "Marietta" },
        { title: "Atlanta" },
        { title: "Douglasville" },
        { title: "Stone Mountain" },
        { title: "Smyrna" },
        { title: "Kennesaw" },
        { title: "Dallas" },
        { title: "Woodstock" },
        { title: "Austell" },
        { title: "Holly Springs" },
        { title: "Acworth" },
        { title: "Lilburn" },
        { title: "Canton" },
        { title: "Fairburn" },
        { title: "Temple" },
        { title: "Decatur" },
        { title: "Clarkdale" },
        { title: "Powder Springs" },
        { title: "Mableton" },
        { title: "Villa Rica" },
        { title: "Hiram" },
        { title: "Lithia Springs" },
        { title: "Norcross" },
        { title: "Winston" },
        { title: "Peachtree Corners" },
      ]
    }),

    computed: {
      numItems () {
        return length(this.serviceAreas)
      },

      cols () {
        const { lg, sm } = this.$vuetify.breakpoint
        return lg ? [3, 9] : sm ? [9, 3] : [6, 6]
      },

    },
    
  }
</script>