<template>
  <svg xmlns="http://www.w3.org/2000/svg" 
    :width="width" 
    :height="height" 
    :aria-labelledby="iconName" 
    role="presentation"
    viewBox="0 0 516 363">
    <title>{{ iconName }}</title>
    <g :fill="iconColor">
      <path class="cls-1" d="M509.49,27.72,354.21,182,509.49,336.28A44.44,44.44,0,0,0,514,317V47A44.44,44.44,0,0,0,509.49,27.72Z"/><path class="cls-1" d="M469,2H47A44.44,44.44,0,0,0,27.72,6.51L226.18,204a45,45,0,0,0,63.64,0L488.28,6.51A44.44,44.44,0,0,0,469,2Z"/><path class="cls-1" d="M6.51,27.72A44.44,44.44,0,0,0,2,47V317a44.44,44.44,0,0,0,4.51,19.28L161.79,182Z"/><path class="cls-1" d="M333,203.21l-22,22a75.08,75.08,0,0,1-106.06,0l-22-22L27.72,357.49A44.44,44.44,0,0,0,47,362H469a44.44,44.44,0,0,0,19.28-4.51Z"/>
    </g>
  </svg>
</template>

<script>
export default {
  props: {
    iconName: {
      type: String,
      default: 'box'
    },
    width: {
      type: [Number, String],
      default: 18
    },
    height: {
      type: [Number, String],
      default: 18
    },
    iconColor: {
      type: String,
      default: 'currentColor'
    },
  }
}
</script>

<style scoped>
svg {
  display: inline-block;
  vertical-align: baseline;
  margin-bottom: -2px; /* yes, I'm that particular about formatting */
}
</style>