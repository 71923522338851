<template>
  <v-main>
    <Home/>
    <p class="py-5 py-md-8"></p>
    <AboutUs/>
    <p class="py-5 py-md-8"></p>
    <Services/>
    <p class="py-5 py-md-8"></p>
    <MoldTypes class="d-none d-sm-block"/>
    <p class="py-5 py-md-8 d-none d-sm-block"></p>
    <HowItWorks/>
    <p class="py-5 py-md-8"></p>
    <Equipment/>
    <p class="py-5 py-md-8"></p>
    <ServiceArea/>
    <p class="py-5 py-md-8"></p>
    <WhyUs/>
    <p class="py-5 py-md-8"></p>
    <Gallery/>
    <p class="py-5 py-md-8"></p>
    <Reviews/>
    <p class="py-5 py-md-8"></p>
    <OrderProcess/>
    <p class="py-5 py-md-8"></p>
    <Pricing/>
    <p class="py-5 py-md-8"></p>
    <ContactUs/>
    <p class="py-5 py-md-8"></p>
    <Footer/>
  </v-main>
</template>

<script>
  import Home from './Home';
  import AboutUs from './AboutUs';
  import Services from './Services';
  import MoldTypes from './MoldTypes';
  import HowItWorks from './HowItWorks';
  import Equipment from './Equipment';
  import ServiceArea from './ServiceArea';
  import WhyUs from './WhyUs';
  import Gallery from './Gallery';
  import Reviews from './Reviews';
  import OrderProcess from './OrderProcess';
  import Pricing from './Pricing';
  import ContactUs from './ContactUs';
  import Footer from './Footer';
  
  export default {
    name: 'MainPage',
    components: {
      Home,
      AboutUs,
      Services,
      MoldTypes,
      HowItWorks,
      Equipment,
      ServiceArea,
      WhyUs,
      Gallery,
      Reviews,
      OrderProcess,
      Pricing,
      ContactUs,
      Footer
    },   
    data: () => ({
      //
    }),
  }
</script>