var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"ma-0 pa-0",attrs:{"id":"equipment","fluid":""}},[_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{staticClass:"hidden-sm-and-down",attrs:{"cols":"2"}}),_c('v-col',{staticClass:"px-4 px-sm-6 px-lg-13",attrs:{"cols":"12","md":"5"}},[_c('p',{staticClass:"font-weight-medium text-h5 text-md-h4 text-lg-h3 primary--text"},[_vm._v("Equipment")])]),_c('v-spacer')],1),_c('v-row',{staticClass:"mt-md-8",attrs:{"no-gutters":""}},[_c('v-col',{staticClass:"hidden-sm-and-down order-md-0",attrs:{"cols":"2","align":"right"}}),_c('v-col',{staticClass:"px-4 px-sm-6 px-lg-13 order-last order-sm-0 order-md-1",attrs:{"cols":"12","sm":"7","align":"left"}},[_c('v-container',[_c('v-row',[_c('v-col',{staticClass:"px-0",attrs:{"cols":"1"}},[_c('v-divider',{staticClass:"secondary",attrs:{"vertical":"","role":"presentation"}})],1),_c('v-col',{staticClass:"pl-0 text-subtitle-1 font-weight-bold",attrs:{"cols":"11"}},[_vm._v(" Air Scrubber: ")])],1),_c('v-row',[_c('v-col',{attrs:{"cols":"1"}}),_c('v-col',{staticClass:"pt-0 pl-0 text-subtitle-1 font-weight-regular",attrs:{"cols":"11"}},[_vm._v(" Portable filtration system that efficiently removes particles from the air to help improve air quality. ")])],1)],1)],1),_c('v-col',{staticClass:"hidden-sm-and-down order-md-2 order-sm-1",attrs:{"cols":"1"}}),_c('v-col',{staticClass:"px-4 px-sm-0 order-md-3 order-sm-2 order-first",attrs:{"cols":"12","sm":"2"}},[_c('v-img',{staticClass:"mr-md-13",attrs:{"contain":"","eager":"","position":_vm.mobileEquipmentImagesPosition,"max-height":_vm.scrubberHeight,"src":require("../assets/images/scrubber_3x_md.jpg"),"srcset":require('../assets/images/scrubber_3x_lg.jpg') + ' 342w,' +
              require('../assets/images/scrubber_3x_md.jpg') + ' 285w,' +
              require('../assets/images/scrubber_2x_lg.jpg') + ' 228w,' +
              require('../assets/images/scrubber_2x_md.jpg') + ' 190w,' +
              require('../assets/images/scrubber_1x_lg.jpg') + ' 114w,' +
              require('../assets/images/scrubber_1x_md.jpg') + ' 95w,'}})],1)],1),_c('v-row',{staticClass:"mt-md-8",attrs:{"no-gutters":""}},[_c('v-col',{staticClass:"hidden-sm-and-down order-md-0",attrs:{"cols":"2","align":"right"}}),_c('v-col',{staticClass:"px-4 px-sm-6 px-lg-13 order-last order-sm-0 order-md-1",attrs:{"cols":"12","sm":"7","align":"left"}},[_c('v-container',[_c('v-row',[_c('v-col',{staticClass:"px-0",attrs:{"cols":"1"}},[_c('v-divider',{staticClass:"secondary",attrs:{"vertical":"","role":"presentation"}})],1),_c('v-col',{staticClass:"pl-0 text-subtitle-1 font-weight-bold",attrs:{"cols":"11"}},[_vm._v(" Air Mover: ")])],1),_c('v-row',[_c('v-col',{attrs:{"cols":"1"}}),_c('v-col',{staticClass:"pt-0 pl-0 text-subtitle-1 font-weight-regular",attrs:{"cols":"11"}},[_vm._v(" Produces high volumes of air movement to allow for proper drying to occur. ")])],1)],1)],1),_c('v-col',{staticClass:"hidden-sm-and-down order-md-2 order-sm-1",attrs:{"cols":"1"}}),_c('v-col',{staticClass:"px-4 px-sm-0 order-md-3 order-sm-2 order-first",attrs:{"cols":"12","sm":"2","align":"right"}},[_c('v-img',{staticClass:"mr-13",attrs:{"contain":"","eager":"","position":_vm.mobileEquipmentImagesPosition,"max-height":_vm.airMoverHeight,"src":require("../assets/images/mover_3x_md.jpg"),"srcset":require('../assets/images/mover_3x_lg.jpg') + ' 342w,' +
              require('../assets/images/mover_3x_md.jpg') + ' 285w,' +
              require('../assets/images/mover_2x_lg.jpg') + ' 228w,' +
              require('../assets/images/mover_2x_md.jpg') + ' 190w,' +
              require('../assets/images/mover_1x_lg.jpg') + ' 114w,' +
              require('../assets/images/mover_1x_md.jpg') + ' 95w,'}})],1)],1),_c('v-row',{staticClass:"mt-md-8",attrs:{"no-gutters":""}},[_c('v-col',{staticClass:"hidden-sm-and-down order-md-0",attrs:{"cols":"2","align":"right"}}),_c('v-col',{staticClass:"px-4 px-sm-6 px-lg-13 order-last order-sm-0 order-md-1",attrs:{"cols":"12","sm":"7","align":"left"}},[_c('v-container',[_c('v-row',[_c('v-col',{staticClass:"px-0",attrs:{"cols":"1"}},[_c('v-divider',{staticClass:"secondary",attrs:{"vertical":"","role":"presentation"}})],1),_c('v-col',{staticClass:"pl-0 text-subtitle-1 font-weight-bold",attrs:{"cols":"11"}},[_vm._v(" Dehumidifier: ")])],1),_c('v-row',[_c('v-col',{attrs:{"cols":"1"}}),_c('v-col',{staticClass:"pt-0 pl-0 text-subtitle-1 font-weight-regular",attrs:{"cols":"11"}},[_vm._v(" Much like air conditioners; warmer, moist air enters the dehumidifier and then crosses refrigerant cooled coils, causing the moisture in the air to condense on coils within the machine. ")])],1)],1)],1),_c('v-col',{staticClass:"hidden-sm-and-down order-md-2 order-sm-1",attrs:{"cols":"1"}}),_c('v-col',{staticClass:"px-4 px-sm-0 order-md-3 order-sm-2 order-first",attrs:{"cols":"12","sm":"2","align":"right"}},[_c('v-img',{staticClass:"mr-13",attrs:{"contain":"","eager":"","position":_vm.mobileEquipmentImagesPosition,"max-height":_vm.dehumidifierHeight,"src":require("../assets/images/dehumidifier_3x_md.jpg"),"srcset":require('../assets/images/dehumidifier_3x_lg.jpg') + ' 225w,' +
              require('../assets/images/dehumidifier_3x_md.jpg') + ' 183w,' +
              require('../assets/images/dehumidifier_2x_lg.jpg') + ' 150w,' +
              require('../assets/images/dehumidifier_2x_md.jpg') + ' 122w,' +
              require('../assets/images/dehumidifier_1x_lg.jpg') + ' 75w,' +
              require('../assets/images/dehumidifier_1x_md.jpg') + ' 61w,'}})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }