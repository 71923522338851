<template>
  <svg xmlns="http://www.w3.org/2000/svg" 
    :width="width" 
    :height="height" 
    :aria-labelledby="iconName" 
    role="presentation"
    viewBox="0 0 372 520">
    <title>{{ iconName }}</title>
    <g :fill="iconColor">
      <path d="M186.07,3.56A182.82,182.82,0,0,0,3.25,186.38C3.25,300.56,130,460,172.43,510.17a17.87,17.87,0,0,0,27.29,0C242.14,460,368.9,300.56,368.9,186.38,368.9,85.41,287,3.56,186.07,3.56ZM186,283.5A93.5,93.5,0,1,1,279.5,190,93.5,93.5,0,0,1,186,283.5Z"/>
    </g>
  </svg>
</template>

<script>
export default {
  props: {
    iconName: {
      type: String,
      default: 'box'
    },
    width: {
      type: [Number, String],
      default: 18
    },
    height: {
      type: [Number, String],
      default: 18
    },
    iconColor: {
      type: String,
      default: 'currentColor'
    },
  }
}
</script>

<style scoped>
svg {
  display: inline-block;
  vertical-align: baseline;
  margin-bottom: -2px; /* yes, I'm that particular about formatting */
}
</style>