<template>
  <svg xmlns="http://www.w3.org/2000/svg" 
    :width="width" 
    :height="height" 
    :aria-labelledby="iconName" 
    role="presentation"
    viewBox="0 0 518 520">
    <title>{{ iconName }}</title>
    <g>
      <circle :fill="iconColor" cx="259.5" cy="259.5" r="256.5"/>
      <polygon :fill="backgroundColor" points="275 254 275 100 243 100 243 267 343.44 367.56 366.06 344.94 275 254"/>
    </g>
  </svg>
</template>

<script>
export default {
  props: {
    iconName: {
      type: String,
      default: 'box'
    },
    width: {
      type: [Number, String],
      default: 18
    },
    height: {
      type: [Number, String],
      default: 18
    },
    iconColor: {
      type: String,
      default: 'currentColor'
    },
    backgroundColor: {
      type: String,
      default: '#fff'
    },
  }
}
</script>

<style scoped>
svg {
  display: inline-block;
  vertical-align: baseline;
  margin-bottom: -2px; /* yes, I'm that particular about formatting */
}
</style>